
import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const ResidentialServiceHero = () => {

    const data = useStaticQuery(
        graphql`
          query {
            residentialHero: file(relativePath: { eq: "residential-service-hero.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `
      )

      const imageData = data.residentialHero.childImageSharp.fluid
  
    return(
        <BackgroundImage Tag="section" fluid={imageData} style={{minHeight: '100vh', opacity: '10%'}}>
            <Container fluid>
            <Row className="services-box-padding"></Row>
                        <Row>
                            <Col></Col>
                                    <Col xs={10} lg={8}>
                                        <div style={{backgroundColor: '#F5F5F5', width:'100%', padding: '10px', opacity: '95%'}}>
                                            <Row className="text-center">
                                                <Col>
                                                    <h2>Our Residential Cleaning Services</h2>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row className="text-center">
                                                <Col><p>We will provide you with the same cleaners every time so that they will get to know your property in detail. Our cleaners are fully vetted and background–checked and, of course, very experienced. We understand that reliability and trust are paramount to our customers, so we ensure that we only recruit the best to be part of our team.</p></Col>
                                            </Row>
                                                                                       
                                  </div> 
                              </Col>
                            <Col></Col>
                          </Row>
                
            </Container>
            </BackgroundImage>
    )
   
  
}



export default ResidentialServiceHero

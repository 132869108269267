
import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

const ResidentialServiceOfferings = () => {

    const data = useStaticQuery(
        graphql`
          query {
            oneOff: file(relativePath: { eq: "one-off.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            regular: file(relativePath: { eq: "regular-cleaning.jpg" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            holiday: file(relativePath: { eq: "holiday-let.jpg" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              ironing: file(relativePath: { eq: "ironing-services.jpg" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
          }
        `
      )

      const oneoffCleaning = data.oneOff.childImageSharp.fluid
      const regularCleaning = data.regular.childImageSharp.fluid
      const holidayCleaning = data.holiday.childImageSharp.fluid
      const ironingService = data.ironing.childImageSharp.fluid
  
    return(
        
        <section className="service-offerings-section">
            <Container>
              <Row className="service-offer">
                  <Col md={3}>
                      <Img fluid={oneoffCleaning} className="service-offering-img"/>
                  </Col>
                  <Col md={9}>
                      <h2 className="service-offer-name">One Off Cleans</h2>
                      <hr className="service-offer-hr"/>
                      <p className="text-justify">Life sometimes calls for a big cleaning job, but you don’t require a <span className="accent-text">regular housework service.</span> Perhaps you have an event like a family gathering or party coming up or just a spring clean.
Our One-Off Cleaning Services are tailored to your individual needs and requirements. We will build a bespoke package just for you, based on the extent of the work you are looking for and the expectation of what you want your home to look like after a clean. Before starting to work, we will arrange a home consultation to meet with you in person, view your property and get a full understanding of your cleaning needs. No matter what you’re after, we promise you a <span className="accent-text">spotless cleaning service.</span>
</p>
                      <p className="prices-text">Prices from £13 p/h</p>
                  </Col>
              </Row>
              <Row className="service-offer">
                  <Col md={3}>
                  <Img fluid={regularCleaning} className="service-offering-img"/>
                  </Col>
                  <Col md={9}>
                      <h2 className="service-offer-name">Regular Cleaning</h2>
                      <hr className="service-offer-hr"/>
                      <p className="text-justify">This is a weekly bespoke cleaning service. Every home is different and without seeing it there is no practical way to quote for cleaning it. Therefore Bright & Clean visit every home (without charge or obligation) so we can quote for a regular service to suit each individual client. Without seeing your home and sitting down with you or your family, there is no way we can quote accurately for time and services, as we always make sure that we’re using the time we have with you to ensure we’re making a real difference.</p> <p>Once we’ve met with you and seen your home, our team will work with you to put together your personal plan, which will tell you how much time we will spend with you, the services we’ll carry out and how often we’ll visit. Then, you can sit back and relax, whilst Bright & Clean makes sure everything runs as it should.</p>
                      <p className="prices-text">Prices from £13 p/h</p>
                  </Col>
              </Row>
              {/* <Row className="service-offer">
                  <Col md={3}>
                  <Img fluid={holidayCleaning} className="service-offering-img"/>
                  </Col>
                  <Col md={9}>
                      <h2>Holiday Let Cleans</h2>
                      <hr/>
                      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores.</p>
                      <p className="prices-text">Prices from £p/h</p>
                  </Col>
              </Row> */}
              <Row className="service-offer">
                  <Col md={3}>
                  <Img fluid={ironingService} className="service-offering-img"/>
                  </Col>
                  <Col md={9}>
                      <h2 className="service-offer-name">Ironing Services</h2>
                      <hr className="service-offer-hr"/>
                      <p className="text-justify">Another chore that nobody looks forward to is ironing, so why not take advantage of our home ironing service? Not only is it the same price as our house cleaning services, and in most cases can be completed by your regular cleaner, but its all done in your home which means your clothes are pressed and put away the same day. There’s no need to panic about when your fresh ironing will be returned to you, or if any items will have gone missing – you can relax knowing that your favorite outfit is hanging in the wardrobe ready.</p>
                      <p className="prices-text">Prices from £13 p/h</p>
                  </Col>
              </Row>
                
            </Container>
        </section>
    )
   
  
}



export default ResidentialServiceOfferings

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const WhyUseResidential = () => {

    return (

        <section className="why-use-section bg-yellow">
            <Container>
                <Row style={{paddingBottom: '1rem'}}>
                    <Col>
                        <h2>Why use Bright and Clean for your Residential Cleaning Needs</h2>
                    </Col>
                </Row>
                <hr style={{border: '1px solid #AED9E0', width: '10%', margin: '0px'}}/>
                <Row style={{paddingTop: '1rem'}}>
                    <Col>
                        <p className="text-justify">No sooner have you thought “TGIF” when your mind suddenly turns to the pile-up of tasks waiting for you at home. You find yourself thinking, “If I could just take one more task off my to-do list, I could enjoy more of the weekend.” Bright and Clean is delighted to tackle the residential cleaning jobs that you dread. Imagine the hours you’ll give back to yourself when a professional residential cleaning service arrives to declutter your kitchen, dust off the cobwebs, and make your bathrooms sparkle. </p>
                        <p className="text-justify">With our house cleaning services, you’ll no longer have to think twice about inviting a friend over for coffee or drinks. Your home can be dust-free and company-ready every day of the week. While we clean your home, you can enjoy your life, spent time with your children, take a class, start a new project. Time is so valuable. Then, you are better of when you delegate your cleaning to us while you take advantage of the time in a productive way.</p>
                    </Col>
                </Row>
            </Container>
        </section>


    )
}

export default WhyUseResidential
import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ResidentialServiceHero from "../components/services/residential/residential-service-hero"
import ResidentialServiceOfferings from "../components/services/residential/service-offering"
import WhyUseResidential from "../components/services/residential/why-use"
import GetInTouch from "../components/get-in-touch"

const ResidentialCleaningPage = () => (
  <Layout>
    <SEO title="Residential Cleaning Services In Chichester"/>
    <ResidentialServiceHero/>
    <ResidentialServiceOfferings/>
    <WhyUseResidential/>
    <GetInTouch/>
    
    
  </Layout>
)

export default ResidentialCleaningPage